import React from 'react';
import type { CardFields, StringFieldDefinition } from '@wilm/common';
import Typography from 'components/commercetools-ui/atoms/typography';
import useResolveCCImage from 'components/commercetools-ui/organisms/checkout/hooks/useResolveCCImage';
import { useCheckoutPaymentContext } from 'components/commercetools-ui/organisms/checkout/provider/payment';
import CreditCardFields from 'components/cybersource/credit-cart-fields';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount, useCart } from 'frontastic';
import Image from 'frontastic/lib/image';

const CreditCard: React.FC = () => {
    const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
    const { transaction, isLoading: cartIsLoading, taxed } = useCart();
    const { account } = useAccount();
    const resolveImageByNumber = useResolveCCImage();
    const { payWithSavedCard, setPayWithSavedCard, paymentIsLoading, cardFields, setCardFields, cardFieldsErrors } =
        useCheckoutPaymentContext();

    if (cartIsLoading || !taxed) return <></>;

    return account?.token?.paymentToken && payWithSavedCard && transaction.total.currencyCode === account?.token.currencyCode ? (
        <>
            <div className="pb-16 pt-12 md:max-w-[436px]">
                {formatCheckoutMessage({
                    id: 'checkout.pay.with.saved.token',
                    defaultMessage: 'Pay with your saved card'
                })}
            </div>

            <div className="mt-16 flex items-center justify-between rounded-md border px-16 py-12 lg:p-24">
                <div className="flex items-center">
                    {resolveImageByNumber(account.token.cardNumber) && (
                        <Image className="h-fit w-32" src={resolveImageByNumber(account.token.cardNumber)} />
                    )}
                    <Typography className="ml-16 text-14 text-primary-black">
                        {account?.token?.cardNumber && account.token.cardExpiryMonth && account.token.cardExpiryYear
                            ? `...${account.token.cardNumber.substring(
                                  account.token.cardNumber.length - 4,
                                  account.token.cardNumber.length
                              )} ${account.token.cardExpiryMonth}/${account.token.cardExpiryYear}`
                            : formatCheckoutMessage({
                                  id: 'checkout.my.saved.card',
                                  defaultMessage: 'My saved card'
                              })}
                    </Typography>
                </div>
            </div>
            <button className="mt-32 cursor-pointer underline md:max-w-[436px]" onClick={() => setPayWithSavedCard(false)}>
                {formatCheckoutMessage({
                    id: 'checkout.add.new.card',
                    defaultMessage: 'Pay with new card'
                })}
            </button>
        </>
    ) : (
        <div className="md:max-w-[436px]">
            <CreditCardFields
                paymentIsLoading={paymentIsLoading}
                cardFields={cardFields}
                cardFieldsErrors={cardFieldsErrors}
                setCardField={(field: keyof CardFields, value: string) => {
                    if (field === 'expiryYear') {
                        value = '20' + value;
                    }
                    setCardFields(prev => ({
                        ...prev,
                        [field]: {
                            ...prev[field],
                            value
                        } as StringFieldDefinition
                    }));
                }}
            />
        </div>
    );
};

export default CreditCard;
