import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { useRouter } from 'next/navigation';
import type { Cart } from '@commercetools/frontend-domain-types/cart';
import type { CheckoutSettings, Order, FieldErrors } from '@wilm/common';
import {
    AccountStatus,
    PaymentMethodType,
    cardFields as initialCardFields,
    validateCardFields,
    FieldsValidationError,
    InstalmentHelper,
    AccountType
} from '@wilm/common';
import toast from 'react-hot-toast';
import { mutate } from 'swr';
import DiscountErrorModal from 'components/commercetools-ui/organisms/checkout/components/discount-codes-error-modal';
import ErrorModal from 'components/commercetools-ui/organisms/checkout/components/error-modal';
import { useResolveCardType } from 'components/commercetools-ui/organisms/checkout/hooks/useResolveCCImage';
import track from 'helpers/gtm';
import TagsActionType from 'helpers/gtm/actions/types/tagsActionType';
import { useFormat } from 'helpers/hooks/useFormat';
import usePath from 'helpers/hooks/usePath';
import useSessionStorage from 'helpers/hooks/useSessionStorage';
import { addInitialPaymentToGetTokenContext, failOrderById, tokenizeUser } from 'helpers/utils/payment';
import { useCurrencyMapperThresholdContext } from 'providers/currency-mapper-threshold';
import type { MakeCCPaymentResult } from 'providers/cybersource';
import { useCybersourceContext } from 'providers/cybersource';
import { sdk } from 'sdk';
import { useAccount, useCart } from 'frontastic';
import type { TermsError } from 'frontastic/hooks/useCart/types';
import type { PaymentData, PaymentProvider, CreditCardData, DeviceDataParams } from './types';
import useMappers from '../../components/steps/sections/addresses/hooks/useMappers';
import useAddress from '../../hooks/useAddress';

interface CheckoutPaymentProviderProps {
    checkoutSettings: CheckoutSettings;
    children?: React.ReactNode;
}

enum CardPaymentFlow {
    EARLY_TOKENIZATION = 'EARLY_TOKENIZATION',
    TOKENIZED_CARD = 'TOKENIZED_CARD',
    NORMAL_FLOW = 'NORMAL_FLOW'
}

export const CheckoutPaymentContext = React.createContext<PaymentProvider>({} as PaymentProvider);

const CheckoutPaymentProvider: React.FC<CheckoutPaymentProviderProps> = ({ checkoutSettings, children }) => {
    const router = useRouter();

    const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });

    const { pathWithoutQuery } = usePath();

    const {
        data: cartData,
        transaction,
        isLoading: cartIsLoading,
        taxed,
        createPendingOrder,
        updateOrderPaymentStateFromTransactions,
        placeCreatedOrder,
        unsetCartFromSession
    } = useCart();

    const { mutateAccount, account } = useAccount();
    const { isValidAddressForPaymentMethod } = useAddress();
    const { accountAddressToAddress } = useMappers();
    const isValidBillingAddressForCardPayment = useMemo(
        () =>
            cartData?.billingAddress &&
            account &&
            isValidAddressForPaymentMethod(accountAddressToAddress(cartData.billingAddress), PaymentMethodType.CARD, account),
        [cartData?.billingAddress, account, isValidAddressForPaymentMethod, accountAddressToAddress]
    );

    const { currencyMapperThreshold } = useCurrencyMapperThresholdContext();

    const resolveCardType = useResolveCardType();

    const {
        numberField,
        cybersourceSettings,
        isAsianMidCurrency,
        makeCCPayment,
        setupMicroform,
        getTransientToken,
        addPaymentWithToken,
        updatePaymentToTriggerAuthFlow
    } = useCybersourceContext();

    const [cardFields, setCardFields] = useState(initialCardFields);

    const [cardFieldsErrors, setCardFieldsErrors] = useState<FieldErrors>({});

    const [paymentIsLoading, setPaymentIsLoading] = useState(false);

    const [payWithSavedCard, setPayWithSavedCard] = useState(true);

    const [tokenSignature, setTokenSignature] = useState('');

    const [clientLibrary, setClientLibrary] = useState('');

    const [clientLibraryIntegrity, setClientLibraryIntegrity] = useState('');

    const [cardType, setCardType] = useState('');

    const [paymentData, setPaymentData] = useState<PaymentData>({} as PaymentData);

    const [processing, setProcessing] = useState(false);

    const [selectedType, setSelectedType] = useSessionStorage<PaymentMethodType>('selectedPaymentMethod', PaymentMethodType.CARD);

    const [sameAsBillingAddress, setSameAsBillingAddress] = useSessionStorage<boolean>('sameAsBillingAddress', true);

    const [availablePaymentMethods, setAvailablePaymentMethods] = useState<{ name: string; index: number }[]>([]);

    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

    const [isDiscountErrorModalOpen, setIsDiscountErrorModalOpen] = useState(false);

    const [customError, setCustomError] = useState('');

    const defaultPaymentMethods = useRef([
        { name: PaymentMethodType.CARD, index: 0 },
        { name: PaymentMethodType.INVOICE, index: 1 }
    ]);

    const allowedAccountStatuses = useRef([AccountStatus.OPEN]);

    const cardPaymentFlow: CardPaymentFlow | null = useMemo(() => {
        if (paymentData.type !== PaymentMethodType.INSTALMENT && paymentData.type !== PaymentMethodType.CARD) {
            return null;
        }

        if (
            !paymentData.withToken &&
            cybersourceSettings.tokenizeEarlyAndUseTokensOnly &&
            !isAsianMidCurrency(transaction.total.currencyCode)
        ) {
            return CardPaymentFlow.EARLY_TOKENIZATION;
        }

        if (paymentData.withToken) {
            return CardPaymentFlow.TOKENIZED_CARD;
        }

        return CardPaymentFlow.NORMAL_FLOW;
    }, [paymentData, cybersourceSettings.tokenizeEarlyAndUseTokensOnly, transaction.total.currencyCode, isAsianMidCurrency]);

    useEffect(() => {
        if (availablePaymentMethods.length > 0 && checkoutSettings) {
            if (checkoutSettings?.defaultPaymentMethod === PaymentMethodType.CARD && checkoutSettings?.creditCardEnabled && !selectedType) {
                setSelectedType(PaymentMethodType.CARD);
                setPaymentData({ ...paymentData, type: PaymentMethodType.CARD } as PaymentData);
            } else if (
                checkoutSettings?.defaultPaymentMethod === PaymentMethodType.INVOICE &&
                checkoutSettings?.invoiceEnabled &&
                !selectedType &&
                availablePaymentMethods.map(pm => pm.name).includes(PaymentMethodType.INVOICE)
            ) {
                setSelectedType(PaymentMethodType.INVOICE);
                setPaymentData({ ...paymentData, type: PaymentMethodType.INVOICE } as PaymentData);
            } else if (
                selectedType &&
                (availablePaymentMethods.map(pm => pm.name).includes(selectedType) || selectedType === PaymentMethodType.INSTALMENT)
            ) {
                setPaymentData({ ...paymentData, type: selectedType } as PaymentData);
            } else {
                setSelectedType(availablePaymentMethods[0].name as PaymentMethodType);
                setPaymentData({ ...paymentData, type: availablePaymentMethods[0].name } as PaymentData);
            }
        }
    }, [checkoutSettings, availablePaymentMethods]);

    useEffect(() => {
        const selected =
            selectedType === PaymentMethodType.CARD ||
            (selectedType === PaymentMethodType.INSTALMENT && checkoutSettings.takeFirstInstalmentPayment);
        if (
            selected && // if credit card is selected
            !cartIsLoading &&
            (!(account?.token?.paymentToken && payWithSavedCard) || // if there is no saved card or user wants to pay with new card
                transaction.total.currencyCode !== account?.token?.currencyCode) && // if currency of cart is different than saved card
            taxed // if cart is taxed
        ) {
            void createPaymentToGetTokenContext(true);
        }
    }, [
        payWithSavedCard,
        selectedType,
        transaction.total.currencyCode,
        account?.token?.paymentToken,
        account?.token?.currencyCode,
        cartIsLoading,
        taxed,
        paymentData.type === PaymentMethodType.INSTALMENT ? paymentData.paymentInstalmentOption : 1
    ]);

    useEffect(() => {
        if (cartData && account && !availablePaymentMethods.length) {
            const accountStatus = account.statusOverride ?? account?.status;
            const allowedStatuses = accountStatus && allowedAccountStatuses.current.includes(accountStatus);

            const lineItemsPaymentMethods =
                allowedStatuses &&
                account?.isB2B &&
                cartData.lineItems?.map(li =>
                    li.variant?.attributes?.supportedPaymentMethods?.map((pm: { key: string; label: string }) => pm.key)
                );
            const invoiceAllowed =
                lineItemsPaymentMethods &&
                lineItemsPaymentMethods.length == lineItemsPaymentMethods.filter(pm => pm?.includes(PaymentMethodType.INVOICE)).length;

            const commonPaymentMethods: { name: string; index: number }[] = [];

            const basketThreshold = currencyMapperThreshold[transaction.subtotal.currencyCode] ?? currencyMapperThreshold.default;
            defaultPaymentMethods.current.forEach(pm => {
                if (
                    pm.name === PaymentMethodType.CARD ||
                    (pm.name === PaymentMethodType.INVOICE &&
                        invoiceAllowed &&
                        transaction.subtotal.centAmount >= basketThreshold * Math.pow(10, transaction.subtotal.fractionDigits))
                ) {
                    commonPaymentMethods.push(pm);
                }
            });

            setAvailablePaymentMethods(commonPaymentMethods);
        }
    }, [cartData, defaultPaymentMethods, account, currencyMapperThreshold]);

    useEffect(() => {
        const numberChangeListener = (data: any) => {
            if (data?.card?.length) {
                setCardType(data.card[0].name);
            }
        };
        if (numberField) {
            numberField.on('change', numberChangeListener);
        }
    }, [numberField]);

    const availableInstalmentOptions = useMemo(() => {
        if (!cartData || !account) return [];
        const accountStatus = account.statusOverride ?? account?.status;
        const lineItemCommonInstalmentOptions = InstalmentHelper.getCartInstalmentOptions(cartData, {
            accountType: account.isB2B ? AccountType.B2B : AccountType.B2C,
            accountStatus: accountStatus
        });
        return lineItemCommonInstalmentOptions;
    }, [cartData, account?.status, account?.statusOverride, account?.isB2B]);

    const makeInvoicePayment = useCallback(async (centAmount: number, currencyCode: string) => {
        const addPaymentByInvoiceResponse = await sdk.callAction<Cart>({
            actionName: `cart/addPaymentByInvoice`,
            payload: {
                payment: {
                    amountPlanned: {
                        currencyCode: currencyCode,
                        centAmount: centAmount
                    }
                }
            }
        });
        return addPaymentByInvoiceResponse;
    }, []);

    const makeInstalmentPayment = useCallback(async (centAmount: number, currencyCode: string, paymentInstalmentOption: number) => {
        const payment = await sdk.callAction<Cart>({
            actionName: `cart/addPaymentByInstalment`,
            payload: {
                payment: {
                    amountPlanned: {
                        currencyCode: currencyCode,
                        centAmount: centAmount
                    }
                },
                paymentInstalmentOption
            }
        });
        return payment;
    }, []);

    const createPaymentToGetTokenContext = useCallback(
        async (renewToken: boolean = false) => {
            if (!tokenSignature || renewToken) {
                const paymentResult = await addInitialPaymentToGetTokenContext(
                    transaction.total.centAmount,
                    transaction.total.currencyCode,
                    'cart',
                    paymentData.type === PaymentMethodType.INSTALMENT ? paymentData.paymentInstalmentOption : 1
                );
                if (paymentResult.isError) {
                    setIsErrorModalOpen(true);
                    setCustomError('');
                    return;
                }

                const payment = paymentResult.payment;
                setTokenSignature(payment.signature);
                setClientLibrary(payment.clientLibrary);
                setClientLibraryIntegrity(payment.clientLibraryIntegrity);
                setupMicroform(payment.signature, payment.clientLibrary, payment.clientLibraryIntegrity);
                setPaymentData({
                    ...paymentData,
                    paymentId: payment.id,
                    paymentMethod: payment.paymentMethod
                } as CreditCardData);
            } else {
                setupMicroform(tokenSignature, clientLibrary, clientLibraryIntegrity);
            }
        },
        [tokenSignature, paymentData, transaction.total.centAmount, transaction.total.currencyCode, setupMicroform]
    );

    const payWithToken = useCallback(
        async (order: Order, paymentToken: string): Promise<{ paymentResult: MakeCCPaymentResult; paymentData: PaymentData }> => {
            let paymentResult: MakeCCPaymentResult;
            if (paymentData.type !== PaymentMethodType.INSTALMENT && paymentData.type !== PaymentMethodType.CARD) {
                paymentResult = {
                    isError: true,
                    needsToStartFromBeginning: true,
                    fieldsErrors: {},
                    errorMessage: 'Error: Could not pay with token if selected payment method is not card'
                };
                return { paymentResult, paymentData };
            }
            if (paymentToken) {
                const meta = {
                    orderNumber: order.orderId,
                    isCheckout: true,
                    paymentInstalmentOption: paymentData.type === PaymentMethodType.INSTALMENT ? paymentData.paymentInstalmentOption : 1
                };

                const grossAmount = order.totalGross?.centAmount ?? 0;

                if (grossAmount <= 0) {
                    return {
                        paymentResult: {
                            isError: true,
                            needsToStartFromBeginning: true,
                            fieldsErrors: {},
                            errorMessage: 'Error: Payment amount is invalid'
                        },
                        paymentData
                    };
                }

                const newPaymentWithToken = await addPaymentWithToken(grossAmount, order.sum.currencyCode, meta);

                let deviceData: DeviceDataParams = {};
                if (
                    newPaymentWithToken.paymentMethod === 'creditCardWithPayerAuthentication' &&
                    newPaymentWithToken.deviceDataParams &&
                    newPaymentWithToken.deviceDataParams.isv_requestJwt !== ''
                ) {
                    const updatedPayment = await updatePaymentToTriggerAuthFlow({ ...meta, paymentId: newPaymentWithToken.id });

                    deviceData = updatedPayment.deviceDataParams as DeviceDataParams;
                    paymentResult = await makeCCPayment(
                        { expirationMonth: cardFields.expiryMonth.value, expirationYear: cardFields.expiryYear.value },
                        { paymentId: newPaymentWithToken.id, isCheckout: true, orderNumber: order.orderId },
                        paymentData.token,
                        updatedPayment
                    );
                    console.info('---> payWithToken makeCCPayment paymentResult', paymentResult);
                } else {
                    paymentResult = { isError: false, needsToStartFromBeginning: false, fieldsErrors: {} };
                }
                const newPaymentData = {
                    ...paymentData,
                    paymentId: newPaymentWithToken.id,
                    maskedCard: newPaymentWithToken.maskedCard,
                    expiryMonth: newPaymentWithToken.expiryMonth,
                    expiryYear: newPaymentWithToken.expiryYear,
                    paymentMethod: newPaymentWithToken.paymentMethod,
                    deviceDataParams: deviceData,
                    paymentCardType: resolveCardType(newPaymentWithToken.maskedCard),
                    withToken: true
                } as PaymentData;

                setPaymentData(newPaymentData);

                console.info('---> payWithToken return', { paymentResult, paymentData: newPaymentData });

                return { paymentResult, paymentData: newPaymentData };
            } else {
                return {
                    paymentResult: {
                        isError: true,
                        needsToStartFromBeginning: true,
                        fieldsErrors: {},
                        errorMessage:
                            'Error: We were unable to process your payment. Please double-check the credit card and address information and try again'
                    },
                    paymentData
                };
            }
        },
        [paymentData, cardFields, resolveCardType, makeCCPayment, addPaymentWithToken, updatePaymentToTriggerAuthFlow]
    );

    const getBackAndResetMicroform = useCallback(() => {
        void createPaymentToGetTokenContext();
        setPaymentIsLoading(false);
        setProcessing(false);
        router.push(`${pathWithoutQuery}?step=1`);
    }, [createPaymentToGetTokenContext, pathWithoutQuery, router]);

    const handleCreateOrderResponse = useCallback(
        (createOrderResponse: Order | TermsError) => {
            const termsError = createOrderResponse as TermsError;
            if (termsError?.error?.message === 'termsNotAccepted') {
                toast.error(
                    formatCheckoutMessage({
                        id: 'terms.not.accepted',
                        defaultMessage: 'Please accept Terms and Conditions to proceed.'
                    })
                );
                return;
            }

            if (termsError?.error?.message === 'Error: discountCodesNotApplicable') {
                setIsDiscountErrorModalOpen(true);
                return;
            }

            if (termsError?.isError) {
                toast.error(
                    formatCheckoutMessage({
                        id: 'order.failed',
                        defaultMessage: 'We could not process your order, please try again later.'
                    })
                );
                return;
            }

            return createOrderResponse as Order;
        },
        [formatCheckoutMessage]
    );

    const onSuccessfulPayment = useCallback(
        (order: Order) => {
            track({ type: TagsActionType.PURCHASE, payload: { order: order, discountCodes: cartData?.discountCodes ?? [] } });
            if (typeof window !== 'undefined') {
                window.sessionStorage.setItem('lastPlacedOrder', JSON.stringify(order));
            }
            void sdk.callAction({ actionName: 'cart/resetCart' });
            router.push('/thank-you');
        },
        [router, cartData?.discountCodes]
    );

    const validatePaymentStep = useCallback(async () => {
        if (account?.token?.paymentToken && payWithSavedCard && transaction.total.currencyCode === account?.token.currencyCode) {
            // pay with saved card
            setPaymentData({
                ...paymentData,
                withToken: true
            } as PaymentData);
            return { isValid: true };
        }

        // pay with new card
        setPaymentIsLoading(true);

        //validate card fields
        const fieldsErrors = validateCardFields(cardFields);
        if (Object.keys(fieldsErrors).length) {
            setCardFieldsErrors(fieldsErrors);
            setPaymentIsLoading(false);
            return { isValid: false };
        }

        let token = '';
        try {
            token = await getTransientToken({ expirationMonth: cardFields.expiryMonth.value, expirationYear: cardFields.expiryYear.value });
        } catch (error) {
            console.error('---> getTransientToken error', error);
            if (error instanceof FieldsValidationError) {
                setCardFieldsErrors(error.errors);
                setPaymentIsLoading(false);
                return { isValid: false };
            } else {
                setIsErrorModalOpen(true);
                setCustomError(
                    formatCheckoutMessage({
                        id: 'payment.failed',
                        defaultMessage: 'We could not process your payment, please try again later.'
                    })
                );
                setPaymentIsLoading(false);
                getBackAndResetMicroform();
                return { isValid: false };
            }
        }
        setCardFieldsErrors({});
        setPaymentData({
            ...paymentData,
            paymentCardType: cardType,
            token,
            tokenizeCard: true,
            withToken: false,
            expiryMonth: cardFields.expiryMonth.value,
            expiryYear: cardFields.expiryYear.value
        } as CreditCardData);

        setPaymentIsLoading(false);
        return { isValid: true, token };
    }, [
        account,
        payWithSavedCard,
        transaction.total.currencyCode,
        paymentData,
        cardType,
        getTransientToken,
        cardFields,
        getBackAndResetMicroform,
        formatCheckoutMessage
    ]);

    const createPaymentError = (message: string, needsToStartFromBeginning = false, fieldsErrors = {}): MakeCCPaymentResult => ({
        isError: true,
        needsToStartFromBeginning,
        fieldsErrors,
        errorMessage: message
    });

    const makeCardPayment = useCallback(
        async (order: Order) => {
            if (
                (paymentData.type !== PaymentMethodType.CARD && paymentData.type !== PaymentMethodType.INSTALMENT) ||
                (paymentData.type === PaymentMethodType.INSTALMENT && !checkoutSettings.takeFirstInstalmentPayment)
            ) {
                return {
                    paymentResult: createPaymentError('Error: Could not pay with card if selected payment method is not card', true)
                };
            }

            const paymentId = paymentData.paymentId;

            switch (cardPaymentFlow) {
                case CardPaymentFlow.TOKENIZED_CARD: {
                    const token = account?.token?.paymentToken;
                    if (!token) {
                        return {
                            paymentResult: createPaymentError('Error: Could not pay with token. Token is missing in account', true)
                        };
                    }

                    const result = await payWithToken(order, token);
                    const newPaymentData = result.paymentData as CreditCardData;
                    setPaymentData(result.paymentData);

                    return {
                        paymentResult: result.paymentResult,
                        paymentId: newPaymentData.paymentId
                    };
                }

                case CardPaymentFlow.EARLY_TOKENIZATION: {
                    const billingAddressId = order.billingAddress?.addressId ?? '';
                    const tokenizedAccount = await tokenizeUser(paymentData.token, billingAddressId, order.sum.currencyCode);

                    const token = tokenizedAccount?.token?.paymentToken;
                    if (!token) {
                        return {
                            paymentResult: createPaymentError('Error: Could not pay with token. Token is missing in account', true)
                        };
                    }

                    const result = await payWithToken(order, token);
                    const newPaymentData = result.paymentData as CreditCardData;
                    setPaymentData(result.paymentData);

                    return {
                        paymentResult: result.paymentResult,
                        paymentId: newPaymentData.paymentId
                    };
                }

                case CardPaymentFlow.NORMAL_FLOW: {
                    const paymentResult = await makeCCPayment(
                        {
                            expirationMonth: cardFields.expiryMonth.value,
                            expirationYear: cardFields.expiryYear.value
                        },
                        {
                            paymentId: paymentData.paymentId,
                            isCheckout: true,
                            orderNumber: order?.orderId,
                            paymentInstalmentOption:
                                paymentData.type === PaymentMethodType.INSTALMENT ? paymentData.paymentInstalmentOption : 1
                        },
                        paymentData.token
                    );

                    return { paymentResult, paymentId };
                }

                default:
                    return {
                        paymentResult: createPaymentError('Invalid card payment flow', true)
                    };
            }
        },
        [paymentData, cardPaymentFlow, cardFields, account?.token?.paymentToken, payWithToken, makeCCPayment]
    );

    const handlePaymentError = useCallback(
        async (error: MakeCCPaymentResult, order: Order) => {
            console.info('---> error', error);

            if (error.needsToStartFromBeginning) {
                setIsErrorModalOpen(true);
                setCustomError(error.errorMessage ?? 'Error: Could not process payment');
                await failOrderById(order.cartId);
                await mutate('/action/cart/getCart');
                getBackAndResetMicroform();
            } else if (Object.keys(error.fieldsErrors).length) {
                setCardFieldsErrors(error.fieldsErrors);
                setPaymentIsLoading(false);
                setProcessing(false);
                router.push(`${pathWithoutQuery}?step=1`);
            } else if (error.errorMessage === 'DuplicatedPayments') {
                setIsErrorModalOpen(true);
                setCustomError('Payment for this basket has already been processed. Please contact support for further assistance.');
            } else {
                // unexpected response
                await unsetCartFromSession();
                await mutate('/action/cart/getCart');
                router.push(`/?unexpectedPaymentError=true`);
            }
        },
        [router, pathWithoutQuery, getBackAndResetMicroform]
    );

    const handleCardPayment = useCallback(
        async (isTermsAccepted: boolean): Promise<MakeCCPaymentResult> => {
            setPaymentIsLoading(true);

            try {
                const paymentInstalmentOption =
                    paymentData.type == PaymentMethodType.INSTALMENT ? paymentData.paymentInstalmentOption : undefined;
                const createOrderResponse = await createPendingOrder(isTermsAccepted, false, paymentInstalmentOption);
                const currentOrder = handleCreateOrderResponse(createOrderResponse);

                if (!currentOrder) {
                    const error = createPaymentError('Error: Could not create order');
                    setIsErrorModalOpen(true);
                    setCustomError(error.errorMessage ?? 'Error: Could not create order');
                    setPaymentIsLoading(false);
                    setProcessing(false);
                    return error;
                }

                const { paymentResult, paymentId } = await makeCardPayment(currentOrder);

                console.info('---> paymentResult', paymentResult);

                if (paymentId && !paymentResult.isError) {
                    const response = await placeCreatedOrder(paymentId);
                    let placedOrder = response as Order;

                    try {
                        const updateOrderRes = await updateOrderPaymentStateFromTransactions(placedOrder);
                        if (updateOrderRes.order) {
                            placedOrder = updateOrderRes.order;
                        }
                    } catch {
                        // continue, the payment was successful
                    }

                    onSuccessfulPayment(placedOrder);
                    await mutateAccount();
                    return paymentResult;
                }

                await handlePaymentError(paymentResult, currentOrder);

                return paymentResult.isError ? paymentResult : createPaymentError('Error: Payment ID is missing', true);
            } catch (error) {
                const paymentError = createPaymentError('Unexpected error during payment processing', true);
                setIsErrorModalOpen(true);
                setCustomError(paymentError.errorMessage ?? 'Error: Unexpected error during payment processing');
                setPaymentIsLoading(false);
                setProcessing(false);
                return paymentError;
            }
        },
        [
            makeCardPayment,
            placeCreatedOrder,
            updateOrderPaymentStateFromTransactions,
            onSuccessfulPayment,
            mutateAccount,
            handleCreateOrderResponse,
            createPendingOrder,
            handlePaymentError
        ]
    );
    const value = useMemo(() => {
        return {
            selectedType,
            paymentData,
            processing,
            sameAsBillingAddress,
            availablePaymentMethods,
            availableInstalmentOptions,
            cardType,
            payWithSavedCard,
            paymentIsLoading,
            cardFields,
            cardFieldsErrors,
            isValidBillingAddressForCardPayment,
            setSameAsBillingAddress,
            setProcessing,
            setSelectedType,
            setPaymentData,
            handleCardPayment,
            onSuccessfulPayment,
            createPaymentToGetTokenContext,
            setPayWithSavedCard,
            setPaymentIsLoading,
            setCardFields,
            validatePaymentStep,
            makeInvoicePayment,
            makeInstalmentPayment
        };
    }, [
        selectedType,
        paymentData,
        processing,
        sameAsBillingAddress,
        availablePaymentMethods,
        availableInstalmentOptions,
        cardType,
        payWithSavedCard,
        paymentIsLoading,
        cardFields,
        cardFieldsErrors,
        isValidBillingAddressForCardPayment,
        setSameAsBillingAddress,
        setProcessing,
        setSelectedType,
        setPaymentData,
        handleCardPayment,
        onSuccessfulPayment,
        createPaymentToGetTokenContext,
        setPayWithSavedCard,
        setPaymentIsLoading,
        setCardFields,
        validatePaymentStep,
        makeInvoicePayment,
        makeInstalmentPayment
    ]);

    return (
        <CheckoutPaymentContext.Provider value={value}>
            {children}
            <ErrorModal isErrorModalOpen={isErrorModalOpen} setIsErrorModalOpen={setIsErrorModalOpen} customError={customError} />
            <DiscountErrorModal isDiscountErrorModalOpen={isDiscountErrorModalOpen} />
        </CheckoutPaymentContext.Provider>
    );
};

export default CheckoutPaymentProvider;

export function useCheckoutPaymentContext() {
    const context = React.useContext(CheckoutPaymentContext);

    if (!context) {
        throw new Error('useCheckoutPaymentContext must be used within the scope of CheckoutPaymentProvider');
    }

    return context;
}
