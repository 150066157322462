import React, { useMemo, useEffect } from 'react';
import Input from 'components/commercetools-ui/atoms/input';
import { useCart, useAccount } from 'frontastic';
import { useFormat } from 'helpers/hooks/useFormat';
import type { DelegatesData } from '@wilm/common';
import type { DelegatesFieldErrors } from '@wilm/shared-backend/commerce-commercetools/validation/interfaces/FieldErrors';

export interface Props {
    index: number;
    lineItemId: string;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    data: DelegatesData;

    setData: (val: any) => void;
    formErrors?: DelegatesFieldErrors;
}

const DelegatesFields: React.FC<Props> = ({ index, handleChange, lineItemId, data, setData, formErrors }) => {
    const { formatMessage } = useFormat({ name: 'common' });
    const { formatMessage: formatValidationMessage } = useFormat({ name: 'validation' });

    const { delegateObj } = useCart();
    const { account } = useAccount();

    const isDelegate = delegateObj?.[lineItemId] && index === 0;

    const initialData: DelegatesData = useMemo(() => {
        const initialData = {
            [`firstName_${lineItemId}_${index}`]: data[`firstName_${lineItemId}_${index}`] ?? '',
            [`lastName_${lineItemId}_${index}`]: data[`lastName_${lineItemId}_${index}`] ?? '',
            [`email_${lineItemId}_${index}`]: data[`email_${lineItemId}_${index}`] ?? ''
        };
        if (isDelegate) {
            initialData[`firstName_${lineItemId}_0`] = account?.firstName ?? '';
            initialData[`lastName_${lineItemId}_0`] = account?.lastName ?? '';
            initialData[`email_${lineItemId}_0`] = account?.email ?? '';
        }
        return initialData;
    }, [lineItemId, index, isDelegate]);

    useEffect(() => {
        setData((prev: DelegatesData) => ({
            ...prev,
            ...initialData
        }));
    }, [initialData]);

    return (
        <div className="border-checkout-border grid gap-12 border-b bg-white pb-20 pt-15 md:grid-cols-3 md:border-0">
            <Input
                id={`firstName_${lineItemId}-${index}`}
                name={`firstName_${lineItemId}_${index}`}
                type="text"
                required
                label={formatMessage({ id: 'firstName', defaultMessage: 'First Name' })}
                onChange={handleChange}
                value={data[`firstName_${lineItemId}_${index}`]}
                disabled={isDelegate}
                errorMessage={
                    formErrors?.[`firstName_${lineItemId}_${index}`]?.message &&
                    formatValidationMessage({
                        id: formErrors?.[`firstName_${lineItemId}_${index}`]?.message ?? 'error.missing.first.name',
                        defaultMessage: 'Enter your first name'
                    })
                }
            />

            <Input
                id={`lastName_${lineItemId}-${index}`}
                name={`lastName_${lineItemId}_${index}`}
                type="text"
                required
                label={formatMessage({ id: 'lastName', defaultMessage: 'Last Name' })}
                onChange={handleChange}
                value={data[`lastName_${lineItemId}_${index}`]}
                disabled={isDelegate}
                errorMessage={
                    formErrors?.[`lastName_${lineItemId}_${index}`]?.message &&
                    formatValidationMessage({
                        id: formErrors[`lastName_${lineItemId}_${index}`]?.message ?? 'error.missing.last.name',
                        defaultMessage: 'Enter your last name'
                    })
                }
            />

            <Input
                id={`email_${lineItemId}-${index}`}
                name={`email_${lineItemId}_${index}`}
                type="email"
                required
                label={formatMessage({ id: 'emailAddress', defaultMessage: 'Email Address' })}
                onChange={handleChange}
                value={data[`email_${lineItemId}_${index}`]}
                disabled={isDelegate}
                errorMessage={
                    formErrors?.[`email_${lineItemId}_${index}`]?.message &&
                    formatValidationMessage({
                        id: formErrors[`email_${lineItemId}_${index}`]?.message ?? 'error.missing.email',
                        defaultMessage: 'Enter your email'
                    })
                }
            />
        </div>
    );
};

export default DelegatesFields;
