import React, { useCallback, useState } from 'react';

import Button from 'components/commercetools-ui/atoms/button';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';
import DelegatesFields from 'components/commercetools-ui/organisms/checkout/components/steps/sections/learner-details/components/delegates-fields';
import type { DelegatesFieldErrors } from '@wilm/shared-backend/commerce-commercetools/validation/interfaces/FieldErrors';
import type { DelegatesData, DuplicatedEmailsError } from '@wilm/common';
import useBundle from 'frontastic/hooks/useBundle';
export interface Props {
    goToNextStep: () => void;
}

const LearningDetails: React.FC<Props> = ({ goToNextStep }) => {
    const { data: cartData, setDelegatesData } = useCart();
    const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
    const [formErrors, setFormErrors] = useState<DelegatesFieldErrors>();

    const { nonBundleItems } = useBundle(cartData?.lineItems ?? []);

    const [data, setData] = useState({});
    const [duplicatedEmailsError, setDuplicatedEmailsError] = useState<DuplicatedEmailsError>({});

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setData({ ...data, [e.target.name]: e.target.value });
        },
        [data]
    );

    const handleSubmit = useCallback(
        async (data: DelegatesData) => {
            const res = await setDelegatesData(data);

            if ('errors' in res) {
                setFormErrors(res.errors);
                setDuplicatedEmailsError({});
            } else if (res.duplicatedEmailsError && Object.values(res.duplicatedEmailsError).includes(true)) {
                setFormErrors(undefined);
                setDuplicatedEmailsError(res.duplicatedEmailsError);
            } else {
                setFormErrors(undefined);
                goToNextStep();
                setDuplicatedEmailsError({});
            }
        },
        [goToNextStep, formErrors]
    );

    return (
        <div className="bg-white pt-16 lg:px-30 lg:pb-36 lg:pt-0">
            <form
                noValidate={true}
                onSubmit={e => {
                    e.preventDefault();

                    void handleSubmit(data);
                }}
            >
                {nonBundleItems?.map(lineItem => (
                    <div key={lineItem.lineItemId} className="mb-20">
                        <p className="mb-10 font-bold text-checkout-delegates">{lineItem.name}</p>

                        {lineItem.count && (
                            <>
                                {Array.from({ length: lineItem.count }).map((_, index) => (
                                    <DelegatesFields
                                        index={index}
                                        handleChange={handleChange}
                                        lineItemId={lineItem.lineItemId}
                                        data={data}
                                        formErrors={formErrors}
                                        setData={setData}
                                        key={`${lineItem.lineItemId}_${index}`}
                                    />
                                ))}
                            </>
                        )}

                        {duplicatedEmailsError[lineItem.lineItemId] && (
                            <p className="relative text-sm leading-tight text-input-error" data-error="true" key={lineItem.lineItemId}>
                                {formatCartMessage({
                                    id: 'email.duplication.error',
                                    defaultMessage: 'Duplicate email addresses detected in the list. Please ensure each email is unique.'
                                })}
                            </p>
                        )}
                    </div>
                ))}

                <Button type="submit">
                    {formatCartMessage({ id: 'continue.to', defaultMessage: 'Continue to' })}{' '}
                    <span className="lowercase">{formatCartMessage({ id: 'payment', defaultMessage: 'Payment' })}</span>
                </Button>
            </form>
        </div>
    );
};

export default LearningDetails;
